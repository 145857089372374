import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import get from 'lodash/get';
import Img from "gatsby-image"
import { graphql, Link } from "gatsby"

class TatouagesPage extends React.Component  {

  constructor(props){
    super(props);
  } 

  render() {
    let illustrations = get(this.props.data, 'prismic.allTatouagess.edges');
    let tatouages = get(this.props.data, 'allInstagramContent.edges');

    return (
      <Layout>
        <SEO  
        title="Tatouages sur Bordeaux - Virginia Garrido Millan"
        description="Découvrez les tatouages et créations graphiques réalisés par Virginia Garrido Millan, artiste bordelaise, tatoueuse et illustratrice sur Bordeaux"
        />
        <div className="l-posts">
          <div className="l-posts__box">
            <div className="container">
              <h1 className="l-posts__title">Mes tatouages</h1>
              <p className="l-posts__text">Découvrez une sélection de tatouages botaniques, floraux et organiques, réalisés pour des amoureux de la nature et de l'art du tatouage. Vous souhaitez prendre rendez-vous ? Vous avez des questions ?</p>
              <div className="l-tatouage__wrapper">
                  {tatouages.map( (illustration, index ) => {
                    return (
                      <div  key= {index} className="l-tatouage__item">
                        <div className="m-post-card">
                            <a target="_blank" href={illustration.node.permalink} class="instagram">
                            <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20 11a1 1 0 00-1 1v6a1 1 0 01-1 1H6a1 1 0 01-1-1V6a1 1 0 011-1h6a1 1 0 100-2H6a3 3 0 00-3 3v12a3 3 0 003 3h12a3 3 0 003-3v-6a1 1 0 00-1-1z" fill="#fff"/><path d="M16 5h1.58l-6.29 6.28a1.002 1.002 0 00.325 1.639 1 1 0 001.095-.219L19 6.42V8a1 1 0 002 0V4a1 1 0 00-1-1h-4a1 1 0 100 2z" fill="#fff"/></svg>
                            </a>
                            <a target="_blank" href={illustration.node.permalink}>
                              <div className="m-post-card__picture">
                                {illustration.node.localImage && 
                                  <Img fluid={illustration.node.localImage.childImageSharp.fluid}  alt ={illustration.node.caption} />
                                } 
                              </div>
                            </a>
                        </div>
                      </div>
                    )
                  })}
              </div> 
            </div>
         </div>
         
        </div>
      </Layout>
    )
  }
}
  
export default TatouagesPage
 
export const query = graphql`
  query Tatouages {
    prismic { 
      allTatouagess {
          edges {
            node {
              name
              thumbnail_image
              thumbnail_imageSharp {
                childImageSharp {
                  fluid(maxWidth: 384, maxHeight: 384, quality  :100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              _meta {
                uid
              }
              _linkType
            }
          }
      }   
    }
    allInstagramContent(filter: {username: {eq: "virginia.garrido.millan"}}) {
      edges {
        node {
          caption
          media_url
          permalink
          localImage {
            childImageSharp {
              fluid(maxWidth: 384, maxHeight: 384, quality  :100) {
                ...GatsbyImageSharpFluid
              }
            } 
          }
        }
      }
    }
  }
`; 


